// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-article-template-article-template-tsx": () => import("./../../../src/templates/ArticleTemplate/ArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-article-template-tsx" */),
  "component---src-templates-category-template-category-template-tsx": () => import("./../../../src/templates/CategoryTemplate/CategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-category-template-category-template-tsx" */),
  "component---src-templates-home-template-home-template-tsx": () => import("./../../../src/templates/HomeTemplate/HomeTemplate.tsx" /* webpackChunkName: "component---src-templates-home-template-home-template-tsx" */)
}

